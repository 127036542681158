<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'RSIA Widaningsih',
			author: 'IT RSIA Widaningsih',
		};
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center">
		<div class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light">
			&copy; {{ copyrightDate }}.
			<a href="/" 
				class="font-general-medium hover:underline hover:text-colorPrimaryDark dark:hover:text-colorPrimaryLight duration-500">
				{{ projectName }}
			</a>
			. Developed by
			<a href="/" 
				class="font-general-medium text-secondary-dark dark:text-secondary-light uppercase hover:underline hover:text-colorPrimaryDark dark:hover:text-colorPrimaryLight duration-500">{{
				author }}</a>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
