// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
  {
    id: 1,
    url: "poli",
    title: "POLIKLINIK",
    category: "Konsultasi seputar kesehatan anda ke poliklinik terkait.",
    img: require("@/assets/images/patient.png"),
  },
  {
    id: 2,
    url: "kamar",
    title: "KAMAR RAWAT INAP",
    category:
      "Pilihan kamar untuk pasien rawat inap, dilengkapi dengan fasilitas yang nyaman.",
    img: require("@/assets/images/inpatient.png"),
  },
  {
    id: 3,
    url: "penunjang",
    title: "PENUNJANG MEDIS",
    category:
      "Layanan penunjang medis untuk mendukung pemeriksaan dan tindakan pasien.",
    img: require("@/assets/images/radiation.png"),
  },
];

export default projects;
