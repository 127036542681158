<script>
import feather from 'feather-icons';
import FooterCopyright from './FooterCopyright.vue';
import { socialLinks } from '../../data/socialLinks';

export default {
	name: 'MapComponent',
	components: { FooterCopyright },
	data() {
		return {
			socials: socialLinks,
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div class="container mx-auto">
		<div class="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 dark:border-secondary-dark card bg-white border border-gray-200 shadow-lg rounded-lg p-4"
			data-aos="fade-up">
			<!-- Footer content -->
			<div class="flex flex-col justify-center items-center mb-12 sm:mb-20">
				<!-- <p class="font-general-semibold text-3xl sm:text-4xl font-semibold text-primary-dark dark:text-primary-light mb-5"
					data-aos="fade-up">Follow me</p> -->
				<div class="text-center my-8">
					<h2 class="text-2xl font-semibold mb-2 text-gray-700 dark:text-gray-300" data-aos="fade-up">Alamat
					</h2>
					<p class="text-lg text-gray-600 dark:text-gray-400 mb-4" data-aos="fade-up">Jl. Sutisna Senjaya
						No.130, Cikalang, Kec. Tawang, Kab. Tasikmalaya, Jawa Barat 46113</p>
					<h2 class="text-2xl font-semibold mb-2 text-gray-700 dark:text-gray-300" data-aos="fade-up">No. Telp
					</h2>
					<p class="text-lg text-gray-600 dark:text-gray-400" data-aos="fade-up">(0265) 334154</p>
				</div>
				<!-- Google Map -->
				<div class="w-full sm:w-96 h-64 card bg-white border border-gray-200 shadow-lg rounded-lg my-4 mx-auto p-4"
					data-aos="fade-up">
					<GMapMap :center="{ lat: -7.3297298, lng: 108.229514 }" :zoom="17" class="h-full w-full">
						<GMapMarker :position="{ lat: -7.3297298, lng: 108.229514 }" :clickable="true"
							:draggable="true" />
					</GMapMap>
				</div>

				<!-- Address and Phone -->
				<!-- <div class="text-center my-8">
					<h2 class="text-2xl font-semibold mb-2 text-gray-700 dark:text-gray-300" data-aos="fade-up">Address
					</h2>
					<p class="text-lg text-gray-600 dark:text-gray-400 mb-4" data-aos="fade-up">Jl. Sutisna Senjaya
						No.130, Cikalang, Kec. Tawang, Kab. Tasikmalaya, Jawa Barat 46113</p>
					<h2 class="text-2xl font-semibold mb-2 text-gray-700 dark:text-gray-300" data-aos="fade-up">Phone
					</h2>
					<p class="text-lg text-gray-600 dark:text-gray-400" data-aos="fade-up">(0265) 334154</p>
				</div> -->

				<!-- Social Links -->
				<ul class="flex gap-4 sm:gap-8" data-aos="fade-up">
					<a v-for="social in socials" :key="social.id" :href="social.url" target="__blank"
						class="text-gray-400 hover:colorPrimaryDark dark:hover:text-colorPrimaryLight cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-500">
						<i :data-feather="social.icon" class="w-5 sm:w-8 h-5 sm:h-8"></i>
					</a>
				</ul>

			</div>

			<!-- Footer copyright -->
			<FooterCopyright />
		</div>
	</div>

</template>

<style scoped>
.h-screen {
	height: 100vh;
}
</style>