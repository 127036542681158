export const socialLinks = [
  {
    id: 1,
    name: "Website",
    icon: "globe",
    url: "/",
  },
  // {
  // 	id: 2,
  // 	name: 'GitHub',
  // 	icon: 'github',
  // 	url: 'https://github.com/realstoman',
  // },
  {
    id: 2,
    name: "Instagram",
    icon: "instagram",
    url: "https://www.instagram.com/rsia_widaningsih",
  },
  {
    id: 3,
    name: "Facebook",
    icon: "facebook",
    url: "https://www.facebook.com/rsiawidaningsihtasikmalaya/?locale=id_ID",
  },

  {
    id: 4,
    name: "LinkedIn",
    icon: "linkedin",
    url: "https://id.linkedin.com/in/rsia-widaningsih-tasikmalaya-57b91420a?original_referer=https%3A%2F%2Fwww.google.com%2F",
  },
  {
    id: 5,
    name: "Tiktok",
    icon: "music",
    url: "https://www.tiktok.com/@rsiawidaningsih",
  },
 
];
