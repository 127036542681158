<template>
	<transition name="fade">
		<div v-show="modal" class="font-general-regular fixed inset-0 z-30">
			<!-- Modal body background as backdrop -->
			<div v-show="modal" @click="showModal(),resetForm()"
				class="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"></div>
			<!-- Modal content -->
			<main class="flex items-center justify-center h-full w-full">
				<transition name="fade-up-down">
					<div v-show="modal" class="modal-wrapper flex items-center z-30">
						<div
							class="modal max-w-md mx-5 md:max-w-xl bg-secondary-light dark:bg-primary-dark max-h-screen shadow-lg flex-col rounded-lg relative">
							<div
								class="modal-header flex justify-between gap-10 p-5 border-b border-ternary-light dark:border-ternary-dark">
								<h5 class="text-primary-dark dark:text-primary-light text-xl">
									Form Pendaftaran Pasien
								</h5>

								<button class="px-4 text-primary-dark dark:text-primary-light" @click="showModal()">
									<i data-feather="x"></i>
								</button>
							</div>
							<form class="max-w-xl m-4 text-left" @submit.prevent="simpanRegOnline">

								<div class="modal-body p-5 w-full overflow-y-auto">
									<div>
										<label
											class="block mb-2 text-lg text-primary-dark dark:text-primary-light">NIK</label>
										<input
											class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300 focus:border-colorPrimaryDark dark:focus:border-colorPrimaryDark focus:ring focus:ring-colorPrimaryLight dark:focus:ring-colorPrimaryDark"
											id="nik" name="nik" placeholder="NIK" aria-label="nik" type="text"
											@change="getDataPSN" v-model="nik" />
									</div>
									<div class="mt-2">
										<label
											class="block mb-2 text-lg text-primary-dark dark:text-primary-light">Tanggal
											Lahir</label>
										<input
											class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300 focus:border-colorPrimaryDark dark:focus:border-colorPrimaryDark focus:ring focus:ring-colorPrimaryLight dark:focus:ring-colorPrimaryDark"
											id="tanggallahir" name="tanggallahir" placeholder="Tanggal Lahir"
											aria-label="tanggallahir" @change="getDataPSN" v-model="tanggallahir"
											type="date" />
									</div>
									<div class="mt-2">
										<label class="block mb-2 text-lg text-primary-dark dark:text-primary-light">Nama
											Lengkap</label>
										<input
											class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300 focus:border-colorPrimaryDark dark:focus:border-colorPrimaryDark focus:ring focus:ring-colorPrimaryLight dark:focus:ring-colorPrimaryDark"
											id="nama" name="nama" placeholder="Nama Lengkap" aria-label="nama"
											type="text" v-model="nama" v-bind:readonly="true" />
									</div>
									<div class="mt-2">
										<label class="block mb-2 text-lg text-primary-dark dark:text-primary-light">No.
											Telepon / WA / HP</label>
										<input
											class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300 focus:border-colorPrimaryDark dark:focus:border-colorPrimaryDark focus:ring focus:ring-colorPrimaryLight dark:focus:ring-colorPrimaryDark"
											id="nohp" name="nohp" placeholder="No. Telepon / WA / HP" aria-label="nohp"
											type="text" v-model="nohp" v-bind:readonly="true" />
									</div>
									<div class="mt-2">
										<label class="block mb-2 text-lg text-primary-dark dark:text-primary-light">Nama
											Ibu</label>
										<input
											class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300 focus:border-colorPrimaryDark dark:focus:border-colorPrimaryDark focus:ring focus:ring-colorPrimaryLight dark:focus:ring-colorPrimaryDark"
											id="namaibu" name="namaibu" placeholder="Nama Ibu" aria-label="namaibu"
											type="text" v-model="namaibu" v-bind:readonly="true" />
									</div>
									<div class="mt-2">
										<label
											class="block mb-2 text-lg text-primary-dark dark:text-primary-light">Tanggal
											Pendaftaran</label>
										<input
											class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300 focus:border-colorPrimaryDark dark:focus:border-colorPrimaryDark focus:ring focus:ring-colorPrimaryLight dark:focus:ring-colorPrimaryDark"
											id="tanggalpendaftaran" name="tanggalpendaftaran"
											placeholder="Tanggal Pendaftaran" aria-label="tanggalpendaftaran"
											v-model="tanggalpendaftaran" type="date" :min="minDate" :max="maxDate" />
									</div>
									<div class="mt-2">
										<label
											class="block mb-2 text-lg text-primary-dark dark:text-primary-light">Pilih
											Poliklinik</label>
										<div class="max-w-xs relative space-y-3">
											<input type="text" id="nm_poli" name="nm_poli" v-model="nm_poli"
												@keyup="getPoli($event.target.value)" placeholder="Cari Poliklinik"
												class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300 focus:border-colorPrimaryDark dark:focus:border-colorPrimaryDark focus:ring focus:ring-colorPrimaryLight dark:focus:ring-colorPrimaryDark">
											<transition name="fade">
												<ul v-if="showOptions"
													class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10 overflow-y-auto max-h-40">
													<li v-for="pols in dataPoli" :key="pols.nm_poli"
														@click="selectpols(pols.kd_poli, pols.nm_poli)"
														class="cursor-pointer hover:bg-gray-100 p-1">
														{{ pols.nm_poli }}
													</li>
												</ul>
											</transition>
											<input type="hidden" id="kd_poli" name="kd_poli" v-model="kd_poli">
										</div>

									</div>
									<div class="mt-2">
										<label
											class="block mb-2 text-lg text-primary-dark dark:text-primary-light">Pilih
											Dokter</label>
										<div class="max-w-xs relative space-y-3">
											<input type="text" id="nm_dokter" name="nm_dokter" v-model="nm_dokter"
												@keyup="getDokter($event.target.value)" placeholder="Cari Dokter"
												class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300 focus:border-colorPrimaryDark dark:focus:border-colorPrimaryDark focus:ring focus:ring-colorPrimaryLight dark:focus:ring-colorPrimaryDark">
											<transition name="fade">
												<ul v-if="showOptionsDok"
													class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10 overflow-y-auto max-h-40">
													<li v-for="doks in dataDokter" :key="doks.nmDokterWithJam"
														@click="selectdoks(doks.kd_dokter, doks.nmDokterWithJam)"
														class="cursor-pointer hover:bg-gray-100 p-1">
														{{ doks.nmDokterWithJam }}
													</li>
												</ul>
											</transition>
											<input type="hidden" id="kd_dokter" name="kd_dokter" v-model="kd_dokter">
										</div>

									</div>
								</div>
								<div class="modal-footer mt-2 sm:mt-0 py-5 px-8 border-t flex justify-between">
									<Button title="Tutup"
										class="px-4 sm:px-6 py-2 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light rounded-md focus:ring-1 focus:ring-indigo-900 duration-500"
										@click="showModal()" aria-label="Tutup Modal" />
									<button :disabled="loading"
										class="px-4 sm:px-6 py-2 sm:py-2.5 text-white bg-colorPrimaryDark hover:bg-colorPrimaryLight rounded-md focus:ring-1 focus:ring-indigo-900 duration-500 flex items-center justify-center"
										aria-label="Daftar">
										<span v-if="!loading">Daftar</span>
										<svg v-if="loading" class="animate-spin h-5 w-5 text-white"
											xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												stroke-width="4"></circle>
											<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z">
											</path>
										</svg>
									</button>
								</div>
							</form>

						</div>
					</div>
				</transition>
			</main>
		</div>
	</transition>
</template>

<script>
import feather from 'feather-icons';
import Button from './reusable/Button.vue';
import axios from 'axios';
import { urlApi } from '../urls.js';

export default {
	props: ['showModal', 'modal', 'categories'],
	components: {
		Button,
	},
	data() {
		return {
			isModalOpen: false,
			nik: '',
			tanggallahir: '',
			data: '',
			nama: '',
			nohp: '',
			namaibu: '',
			loading: false,
			minDate: '',
			maxDate: '',
			kd_poli: '',
			nm_poli: '',
			nm_dokter: '',
			kd_dokter: '',
			showOptions: false,
			showOptionsDok: false,
			dataPoli: [],
			dataDokter: [],
			selectedPoliklinik: null,
			selectedDokter: null,
			selectedItemIds: [],
			selectedItem: null


		};
	},
	mounted() {
		feather.replace();
		const today = new Date();
		const oneWeekLater = new Date();
		oneWeekLater.setDate(today.getDate() + 7);

		this.minDate = this.formatDate(today);
		this.maxDate = this.formatDate(oneWeekLater);
	},
	updated() {
		feather.replace();
	},
	methods: {
		selectpols(kd_poli, nm_poli) {
			this.kd_poli = kd_poli;
			this.nm_poli = nm_poli;
			this.showOptions = false; // Hide options when a pols is selected
		},
		selectdoks(kd_dokter, nm_dokter) {
			this.kd_dokter = kd_dokter;
			this.nm_dokter = nm_dokter;
			this.showOptionsDok = false; // Hide options when a pols is selected
		},

		async simpanRegOnline() {
			this.loading = true;
			// try {
			// 	// Simulate an async operation, such as an API call
			// 	await new Promise(resolve => setTimeout(resolve, 2000));
			// 	// Perform the actual submit action here
			// } catch (error) {
			// 	console.error('Error:', error);
			// } finally {
			// 	this.loading = false;
			// }
			const formData = {
				kd_poli: this.kd_poli,
				kd_dokter: this.kd_dokter,
				nik: this.nik,
				tanggallahir: this.tanggallahir,
				tanggalpendaftaran: this.tanggalpendaftaran,
			};
			try {
				const response = await axios.post(`${urlApi}api/regonline/save-reg`, formData);
				if (response.data.status != "success") {
					this.showAlert('info', response.data.message)
				} else {
					this.resetForm();
					this.showAlert('success', response.data.message)
					this.showModal();
				}

			} catch (error) {
				this.showAlert('info', error)
			} finally {
				this.loading = false;
			}
		},
		resetForm() {
			this.formData = {
				kd_poli: '',
				kd_dokter: '',
				nik: '',
				tanggallahir: '',
				tanggalpendaftaran: ''
			};
		},
		async getPoli(query) {
			console.log("masuk");

			// Di sini Anda dapat mengatur logika untuk memfilter opsi
			// berdasarkan kueri pencarian
			try {
				const response = await axios.get(`${urlApi}api/master/get-poli?q=${query}`);
				if (response.data.status == 'success') {
					this.showOptions = true; // Show options when input is typed
					this.dataPoli = response.data.data ?? []
				}
			} catch (error) {
				console.error('Error fetching options:', error);
			}
		},
		async getDokter(query) {
			const kd_poli = this.kd_poli;

			// Di sini Anda dapat mengatur logika untuk memfilter opsi
			// berdasarkan kueri pencarian
			try {
				const response = await axios.get(`${urlApi}api/masterdokter/get-all-jadwal?search=${query}&kd_poli=${kd_poli}&tanggalpendaftaran=${this.tanggalpendaftaran}&from=REGIS`);
				if (response.data.status == 'success') {
					this.showOptionsDok = true; // Show options when input is typed
					this.dataDokter = response.data.data.data ?? []
				}
			} catch (error) {
				console.error('Error fetching options:', error);
			}
		},
		formatDate(date) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		},
		async handleSubmit() {
			this.loading = true;
			try {
				// Simulate an async operation, such as an API call
				await new Promise(resolve => setTimeout(resolve, 2000));
				// Perform the actual submit action here
			} catch (error) {
				console.error('Error:', error);
			} finally {
				this.loading = false;
			}
		},
		async getDataPSN() {
			if (this.nik == '' || this.tanggallahir == '') {
				return;
			}
			this.$swal.fire({
				title: 'Sedang Mengambil Data',
				showConfirmButton: false,
				allowOutsideClick: false,
				willOpen: () => {
					this.$swal.showLoading();
				}
			});
			try {
				const response = await axios.get(`${urlApi}api/masterpasien/get-pasien?nik=${this.nik}&tanggallahir=${this.tanggallahir}`);
				if (response.data.status != "success") {
					this.showAlert(response.data.status, response.data.message)
				} else {
					this.nama = response.data.data.nm_pasien
					this.nohp = response.data.data.no_tlp
					this.namaibu = response.data.data.nm_ibu
					this.$swal.close();
				}


			} catch (error) {
				this.showAlert('info', "Gagal Mengambil Data !")
			} finally {
				// this.$swal.close();
			}
		},
		toggleModal() {
			this.isModalOpen = !this.isModalOpen;
		},
		beforeUnmount() {
			this.$emit('update:modal', !this.modal);

		},
		showAlert(status, message) {
			this.$swal.fire({
				title: status,
				text: message,
				icon: status
			});
		},
	},
};
</script>

<style scoped>
.bg-gray-800-opacity {
	background-color: #2d374850;
}

.fade-up-down-enter-active {
	transition: all 0.3s ease;
}

.fade-up-down-leave-active {
	transition: all 0.3s ease;
}

.fade-up-down-enter {
	transform: translateY(10%);
	opacity: 0;
}

.fade-up-down-leave-to {
	transform: translateY(10%);
	opacity: 0;
}

.fade-enter-active {
	-webkit-transition: opacity 2s;
	transition: opacity 0.3s;
}

.fade-leave-active {
	transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.modal-body {
	max-height: 60vh;
	/* Adjust as needed */
	overflow-y: auto;
}
</style>
