<template>
	<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">

	<!-- <nav id="nav" class="sm:mx-auto fixed top-0 left-0 w-full sm:px-4 z-10 shadow-md"
		:class="{ 'bg-primary-dark': theme !== 'light', 'bg-white': theme === 'light', }"> -->
	<nav id="nav" class="sm:mx-auto fixed top-0 left-0 w-full sm:px-4 z-10 shadow-md"
		:class="[{ 'bg-primary-dark': theme !== 'light', 'bg-white': theme === 'light' }, navClass]">

		<!-- Header start -->
		<div
			class="max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center my-6 px-4 sm:px-0">
			<!-- Header menu links and small screen hamburger menu -->
			<div class="flex justify-between items-center">
				<!-- Header logos -->
				<div class="flex items-center">
					<router-link to="/">
						<img v-if="theme === 'light'" src="@/assets/images/Logo-WIDA.png" class="w-12 h-12"
							alt="Light Logo" />
						<img v-else src="@/assets/images/Logo-WIDA.png" class="w-12 h-12" alt="Dark Logo" />
					</router-link>
					<h1 class="ml-2 text-lg font-semibold">RSIA Widaningsih</h1>
				</div>


				<!-- Theme switcher small screen -->
				<theme-switcher :theme="theme" @themeChanged="updateTheme" v-if="isVisible = false"
					class="block sm:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2.5 py-2 rounded-lg" />

				<!-- Small screen hamburger menu -->
				<div class="sm:hidden">
					<button @click="isOpen = !isOpen" type="button" class="focus:outline-none"
						aria-label="Hamburger Menu">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
							class="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light">
							<path v-if="isOpen" fill-rule="evenodd"
								d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
								clip-rule="evenodd"></path>
							<path v-if="!isOpen" fill-rule="evenodd"
								d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2z">
							</path>
						</svg>
					</button>
				</div>
			</div>

			<!-- Header links -->
			<AppHeaderLinks :showModal="showModal" @update:navClass="setNavClass" v-model:isOpen="isOpen" />

			<!-- Header right section buttons -->
			<div class="hidden sm:flex justify-between items-center flex-col md:flex-row">
				<!-- Daftar Online button -->
				<div class="hidden md:block">
					<Button title="Daftar Online"
						class="text-md font-general-medium bg-colorPrimaryDark hover:bg-colorPrimaryLight text-white shadow-sm rounded-md px-5 py-2.5 duration-300"
						@click="showModal()" aria-label="Daftar Online" />
				</div>

				<!-- Theme switcher large screen -->
				<theme-switcher :theme="theme" @themeChanged="updateTheme" v-if="isVisible = false"
					class="ml-8 bg-primary-light dark:bg-ternary-dark px-3 py-2 shadow-sm rounded-xl cursor-pointer" />
			</div>

		</div>

		<!-- Daftar Online modal -->
	</nav>
	<div class="main-content pt-20">
		<HireMeModal :showModal="showModal" :modal="modal" :categories="categories" aria-modal="Daftar Online Modal" />

		<!-- Main content goes here -->
	</div>
</template>

<script>
import ThemeSwitcher from '../ThemeSwitcher';
import HireMeModal from '../HireMeModal.vue';
import feather from 'feather-icons';
import AppHeaderLinks from './AppHeaderLinks.vue';
import Button from '../reusable/Button.vue';

export default {
	emits: ['update:modal'],
	components: {
		ThemeSwitcher,
		HireMeModal,
		AppHeaderLinks,
		Button,
	},
	data() {
		return {
			isOpen: false,
			theme: '',
			navClass: '',
			modal: false,
			scrollTimeout: null,
			categories: [
				{
					id: 1,
					value: 'web',
					name: 'Umum',
				},
				{
					id: 2,
					value: 'mobile',
					name: 'Asuransi',
				},

			],
		};
	},

	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		window.addEventListener('scroll', this.handleScroll);
		this.theme = localStorage.getItem('theme') || 'light';
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		setNavClass(newClass) {
			this.navClass = newClass;
			// Set a timeout to show the nav after scrolling stops
			this.scrollTimeout = setTimeout(() => {
				this.navClass = 'nav-show';
			}, 500); // Show nav after 2 seconds of inactivity
		},
		handleScroll() {
			const currentScrollY = window.scrollY;

			if (currentScrollY !== this.lastScrollY) {
				this.navClass = 'nav-hide';
			}

			this.lastScrollY = currentScrollY;
			// Clear any existing timeout
			if (this.scrollTimeout) {
				clearTimeout(this.scrollTimeout);
			}

			// Set a timeout to show the nav after scrolling stops
			this.scrollTimeout = setTimeout(() => {
				this.navClass = 'nav-show';
			}, 500); // Show nav after 2 seconds of inactivity
		},
		updateTheme(theme) {
			this.theme = theme;
		},
		showModal() {
			if (this.modal) {
				// Stop screen scrolling
				document
					.getElementsByTagName('html')[0]
					.classList.remove('overflow-y-hidden');
				this.modal = false;
			} else {
				document
					.getElementsByTagName('html')[0]
					.classList.add('overflow-y-hidden');
				this.modal = true;
			}
			this.$emit('update:modal', !this.modal);
		},
	},
	updated() {
		feather.replace();
	},
};
</script>

<style scoped>
.nav-hide {
	transform: translateY(-100%);
	transition: transform 0.3s ease-in-out;
}

.nav-show {
	transform: translateY(0);
	transition: transform 0.3s ease-in-out;
}

#nav a.router-link-exact-active {
	@apply text-colorPrimaryDark;
	@apply dark:text-colorPrimaryLight;
	@apply font-medium;
}

.main-content {
	padding-top: 80px;
	/* Adjust this value to match the height of your header */
}
</style>
